<template>
    <md-card class="md-elevation-24">
        <md-card-header>
            <md-card-header-text>
            <div class="md-title primary">Roeland de Kruijf</div>
            <div class="md-subhead">Freelance software engineer from Utrecht, Netherlands.</div>
            
            </md-card-header-text>
            <md-card-media md-small>
                <img src="@/assets/roelanddekruijf-bw.jpg" alt="Roeland de Kruijf">
            </md-card-media>
        </md-card-header>
        
        <md-card-content>
            I'm a hands-on software engineer and architect with <span class="md-body-2 accent">{{ yearsExperience() }} years</span> professional experience in <span class="md-body-2 accent">api</span>, <span class="md-body-2 accent">integration</span>, <span class="md-body-2 accent">full-stack</span>, <span class="md-body-2 accent">web</span> and <span class="md-body-2 accent">DevOps</span> development.
            <span class="md-body-2 accent">{{ yearsExperienceAzure() }} years</span> experience with <span class="md-body-2 accent">Azure</span>. <span class="md-body-2 accent">{{ yearsExperienceAgile() }} years</span> using <span class="md-body-2 accent">Scrum</span> and other <span class="md-body-2 accent">Agile</span> methodologies.
            <p>
                Since {{ yearStarted }} I worked with <span class="md-body-2 accent">.NET</span>, <span class="md-body-2 accent">C#</span> and <span class="md-body-2 accent">Visual Studio</span>, starting with <span class="md-body-2 accent">.NET Framework</span> version 1.1 to 4.8, <span class="md-body-2 accent">.NET Core</span> and now where there is one <span class="md-body-2 accent">.NET</span> again.<br />
            </p>
            <p>
                From {{ yearStartedAzure }} deploying solutions through <span class="md-body-2 accent">Azure DevOps</span> with <span class="md-body-2 accent">CI/CD</span> to <span class="md-body-2 accent">Azure App Services</span>, <span class="md-body-2 accent">Azure Functions</span>, <span class="md-body-2 accent">Azure Kubernetes Services</span> and <span class="md-body-2 accent">Azure Container Apps</span>. <br />
                </p>
            <p>
                Experienced with <span class="md-body-2 accent">Event-driven</span> and <span class="md-body-2 accent">Message-driven</span> approaches through topics and queues with <span class="md-body-2 accent">Azure Service Bus</span>, <span class="md-body-2 accent">Azure Event Hubs</span> and similar brokers.<br />
            </p>
            <p>
                Storing data in relational, like <span class="md-body-2 accent">SQL Server</span> and <span class="md-body-2 accent">NoSQL</span>, like <span class="md-body-2 accent">Azure Cosmos DB</span>. <br />
            </p>
            <p>
                Like to use <span class="md-body-2 accent">Node.js</span> and <span class="md-body-2 accent">Docker</span> containers. <br />
            </p>
            <p>
                Became passionate about <span class="md-body-2 accent">JavaScript</span> when <span class="md-body-2 accent">jQuery</span> arrived and 
                nowadays enthusiastic using frameworks like <span class="md-body-2 accent">Vue</span>, <span class="md-body-2 accent">React</span> and <span class="md-body-2 accent">Angular</span> with <span class="md-body-2 accent">TypeScript</span>. 
            </p>
            For more info and getting in touch check my <span class="md-body-2"><a href="https://www.linkedin.com/in/roelanddekruijf">LinkedIn</a></span> profile.
        </md-card-content>

        <md-card-actions>
            <md-button href="https://www.linkedin.com/in/roelanddekruijf" class="md-icon-button md-raised md-primary">
            <img src="@/assets/linkedin.svg" />
            <!-- <md-icon md-src="../assets/linkedin.svg" /> -->
        </md-button>
        </md-card-actions>
    </md-card>
</template>

<script>
export default {
    name: "RoelandCard",
    data: () => {
        return {
            yearStarted: 2004,
            yearStartedAgile: 2009,
            yearStartedAzure: 2012
        }
    },
    methods: {
        yearsExperience: function() {
            return new Date().getFullYear() - this.yearStarted
        },
        yearsExperienceAgile: function() {
            return new Date().getFullYear() - this.yearStartedAgile
        },
        yearsExperienceAzure: function() {
            return new Date().getFullYear() - this.yearStartedAzure
        }
    }
}
</script>

<style lang="scss">
</style>