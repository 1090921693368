<template>
  <div id="home">
    <roeland-card />
  </div>
</template>

<script>
import RoelandCard from "@/components/RoelandCard";

export default {
  name: "Home",
  components: {
    RoelandCard
  }
};
</script>
